import styled from 'styled-components';
import {colors} from '../../../styles/variables';

export const Icon = styled.svg`
	fill: none;
	stroke: ${colors.white};
	strokewidth: 2px;
`;
export const InputStyled = styled.input`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;
export const FancyCheckboxWrapper = styled.div`
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	background: ${props => (props.checked ? colors.carrot : colors.snow)};
	border-radius: 0.5em;
	transition: all 150ms;
	${Icon} {
		visibility: ${props => (props.checked ? 'visible' : 'hidden')};
	}
`;
