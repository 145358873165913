import React from 'react';
import PropTypes from 'prop-types';

import {FancyCheckboxWrapper, Icon, InputStyled} from './styles';

/**
 * @component
 *
 * just the checkbox, without label or state
 * */

const FancyCheckbox = ({checked, onChange}) => (
	<span>
		<InputStyled type="checkbox" checked={checked} onChange={onChange} />
		<FancyCheckboxWrapper checked={checked}>
			<Icon viewBox="0 0 24 24">
				<polyline points="20 6 9 17 4 12" />
			</Icon>
		</FancyCheckboxWrapper>
	</span>
);

FancyCheckbox.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

FancyCheckbox.defaultProps = {
	checked: false,
	onChange: null,
};

export default FancyCheckbox;
