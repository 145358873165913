import React from 'react';
import {hasError} from '../formUtils';
import {LabelForCheckboxStyled, SpanForLabel} from './styles';
import FancyCheckbox from '../../FancyCheckbox/FancyCheckbox';

export const renderCheckboxFieldWithLabel = (labelString, className) => (formikProps) => {
	const {
		field: {name, value},
		form: {setFieldTouched, setFieldValue},
	} = formikProps;

	const onChange = (ev) => {
		setFieldValue(name, ev.target.checked); // because formikProps.field.onChange() doesn't work
		setFieldTouched(name); // always
	};

	function innerText(string) {
		return {__html: string};
	}

	return (
		<LabelForCheckboxStyled hasError={hasError(formikProps)} className={className}>
			<FancyCheckbox checked={value} onChange={onChange} />
			<SpanForLabel dangerouslySetInnerHTML={innerText(labelString)} />
		</LabelForCheckboxStyled>
	);
};

export const renderCheckbox2FieldWithContent = (formikProps, labelContent) => {
	const {
		field: {name, value},
		form: {setFieldTouched, setFieldValue},
	} = formikProps;

	const onChange = (ev) => {
		setFieldValue(name, ev.target.checked); // because formikProps.field.onChange() doesn't work
		setFieldTouched(name); // always
	};

	return (
		<LabelForCheckboxStyled hasError={hasError(formikProps)}>
			<FancyCheckbox checked={value} onChange={onChange} />
			<SpanForLabel>{labelContent}</SpanForLabel>
		</LabelForCheckboxStyled>
	);
};
