import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {renderCheckboxFieldWithLabel, renderCheckbox2FieldWithContent} from './renderFields';

/**
 * @component
 *
 * Checkbox with label (string or component) and connection to Formik
 * */
const Checkbox = ({name, label, validate, className}) => (
	<>
		<Field name={name} validate={validate} className={className}>
			{renderCheckboxFieldWithLabel(label, className)}
		</Field>

		<Field name={name}>{({meta}) => <>{meta.touched && meta.error && <ErrorMessage name={name} />}</>}</Field>
	</>
);

Checkbox.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	validate: PropTypes.func,
	className: PropTypes.string,
};

Checkbox.defaultProps = {
	name: '',
	label: '',
	className: '',
	validate: null,
};

export default Checkbox;

/** @component */
export const Checkbox2 = ({name, labelContent, validate}) => {
	return (
		<>
			<Field
				name={name}
				validate={validate}
				render={(formikProps) => renderCheckbox2FieldWithContent(formikProps, labelContent)}
			/>
			<ErrorMessage name={name} />
		</>
	);
};
Checkbox2.propTypes = {
	name: PropTypes.string,
	labelContent: PropTypes.string,
	validate: PropTypes.bool,
};

Checkbox2.defaultProps = {
	name: '',
	labelContent: '',
	validate: false,
};
