import React from 'react';
import PropTypes from 'prop-types';
import {connect, getIn} from 'formik';
import _ from 'lodash';
import {ObserveModificationConsumer} from '../../../context/observeModificationContext';

/**
 * TODO: description
 */
class ObserveModificationOfFormData extends React.PureComponent {
	static propTypes = {
		formik: PropTypes.object.isRequired,
		onModified: PropTypes.func.isRequired
	};

	isModified = false; // cached value, so that this.props.onModified is only called on 'real' changes

	onModified(newChanged) {
		const {onModified} = this.props;
		if (this.isModified !== newChanged) {
			this.isModified = newChanged;
			onModified(newChanged);
		}
	}

	compareFormValuesWithInitialValues = () => {
		// as this function is rather 'expensive', we debounce it (see below)
		const {formik} = this.props;
		const {initialValues, values} = formik;

		const names = Object.keys(values);
		for (const name of names) {
			const isChanged = getIn(initialValues, name) !== getIn(values, name);
			if (isChanged) {
				this.onModified(true);
				return; // no need to compare further values;
			}
		}
		this.onModified(false);
	};

	deferredCompareFormValuesWithInitialValues = _.debounce(this.compareFormValuesWithInitialValues, 200);

	componentDidUpdate() {
		this.deferredCompareFormValuesWithInitialValues();
	}

	render() {
		// doesn't render anything, as we are only interested in componentDidUpdate
		return null;
	}
}

// connect it both with formik (using connect()), and with the context consumer
export default connect(({formik}) => (
	<ObserveModificationConsumer>
		{context => <ObserveModificationOfFormData formik={formik} onModified={context.onModified} />}
	</ObserveModificationConsumer>
));
