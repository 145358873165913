import styled from 'styled-components';
import {alertColors} from '../../../../styles/variables';

export const LabelForCheckboxStyled = styled.label`
	display: flex;
	font-size: 1rem;
	font-weight: 300;
	padding: 1em;
	border-width: 2px;
	border-radius: 8px;
	border-color: ${(props) => (props.hasError ? alertColors.error.full : 'transparent')};
	cursor: pointer;
`;
export const SpanForLabel = styled.span`
	padding-left: 1em;
	text-align: left;
`;
