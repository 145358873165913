import React from 'react';
import PropTypes from 'prop-types';

import {Formik} from 'formik';
import {Form} from 'react-formik-ui';

import ObserveModificationOfFormData from './ObserveModificationOfFormData';

const SubmitForm = (props) => {
	const {initialValues, renderInnerForm, validationSchema, onSubmit} = props;

	const handleSubmit = (data) => {
		onSubmit(data);
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize // because we want to reconcile initialValues with changed state after submit
			onSubmit={handleSubmit}
			validationSchema={validationSchema}>
			{(formikProps) => (
				<Form mode="structured">
					<ObserveModificationOfFormData />
					{renderInnerForm(formikProps)}
				</Form>
			)}
		</Formik>
	);
};
SubmitForm.propTypes = {
	initialValues: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.bool])),
	onSubmit: PropTypes.func,
	renderInnerForm: PropTypes.func.isRequired,
	validationSchema: PropTypes.objectOf(PropTypes.object),
};

SubmitForm.defaultProps = {
	initialValues: undefined,
	onSubmit: () => {},
	validationSchema: undefined,
};

export default SubmitForm;
